.empty-resource {
  display: flex;
  padding: 25px;
  font-size: 1.05rem;
  text-align: center;
  justify-content: center;
  flex: 0 auto;
  min-height: 440px;
  align-items: center;
}
