.conversation-history {
  white-space: nowrap;
  text-overflow: ellipsis;

  h2 {
    font-size: 0.7rem;
    color: var(--main-text-color);
    opacity: 0.5;
    margin-bottom: 0.5rem;
    margin-left: 0.9rem;
  }

  .history-list {
    color: var(--main-text-color);
  }
}
