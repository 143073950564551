.terms {
  display: flex;
  align-items: center;
  height: 100vh;
  font-size: 1em;

  h1 {
    font-size: 1.7rem;
    color: var(--main-accent-color);
  }

  a {
    color: var(--hyperlink-color);
    text-decoration: underline;
  }
}

.notice {
  margin-bottom: 15px;
  padding: 15px 12px;
  background: var(--notice-bg);
  color: var(--main-text-color);
  border-radius: 6px;

  h4 {
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin-bottom: 5px;
    font-weight: 400;
  }

  p {
    font-size: 0.95rem;
    line-height: 1.2rem;
    color: var(--notice-desc);
  }
}

.notice-proceed {
  width: 100%;

  a {
    font-weight: 600;
  }

  p {
    padding: 10px 0;
  }

  .proceed {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    padding: 1.5rem 0;
    max-width: 100%;
  }
}
