.settings {
  position: relative;
  display: flex;
  flex: 1;
  padding: 1rem 1.5rem;
  align-items: center;
  z-index: var(--z-med-priority);
  justify-content: space-between;
  max-height: 80px;
  max-width: 100%;
  width: 100%;
  transition: background 0.2s ease-out;

  &:after {
    display: none;
    content: '';
    width: 100%;
    height: 1px;
    transition: box-shadow 0.2s ease-out;
  }
}

.settings-modal-wrapper {
  overflow-y: scroll;
  height: 34rem;
}

.-scrolling .settings {
  position: absolute;
  background: transparent !important;

  &:after {
    display: block;
    position: absolute;
    z-index: -1;
    top: -1px;
    left: 0;
    width: 100%;
    box-shadow: 0px 0px 81px 80px var(--scrolling-settings-shadow-color);
  }

  .model-selection button {
    background-color: var(--scrolling-settings-model-button-bg) !important;
    backdrop-filter: blur(5px);
  }
}

.-scrolling .settings.-collapsed-sidebar {
  max-width: 100%;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--avatar-bg);
  color: var(--avatar-text-color);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem;
}

.model-selection {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0 10px;
}

.settings-section {
  padding: 1.2rem 0;
  border-bottom: 1px solid var(--settings-menu-border);

  &.settings-other {
    h3 {
      margin-bottom: 5px;
    }
    button {
      text-wrap: wrap;
      text-align: left;
      align-items: center;
    }
  }
}

.settings-section h3 {
  font-size: 0.95rem;
  margin-bottom: 1.1rem;
  font-weight: 500;
}

.account-name {
  font-size: 1rem;
  color: var(--main-text-color-light);
}

.settings-toggle-theme {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}

.settings-sign-out {
  padding: 1rem 0 0 0;
}

.sign-out-button {
  display: flex;
  color: var(--settings-sign-out);
  padding-left: 0 !important;
  justify-content: left;
  text-align: left;
}

.button-list {
  display: flex;
  flex-direction: column;
}

.button-list button {
  display: flex;
  padding-left: 0 !important;
  margin-bottom: 10px;
}

.button-list-row {
  display: flex;
  align-items: center;
}

.setting-label {
  display: inline-block;
  margin-left: auto;
  font-size: 0.9rem;
  color: var(--main-text-color-light);
}

.model-list .button-list-row {
  margin-bottom: 10px;
}

.version {
  text-align: right;

  &:before {
    content: 'v';
  }
}

.confirm-anonymization-modal {
  .inline-button {
    padding-inline: unset !important;
    span {
      text-decoration: underline;
    }
  }

  .back-button {
    position: absolute;
    top: 20px;
    height: 2.2rem;
    width: 2.2rem;
    background-color: var(--modal-button-background);
    justify-content: center;
    display: flex;
    align-items: center;
    svg {
      height: 0.7rem;
      width: 0.7rem;
    }
  }

  .text-highlight {
    color: var(--button-active-color);
    font-weight: 800;
  }
}

/* sm */
@media (min-width: 640px) {
  .model-selection {
    margin: 0;
  }
}

/* lg */
@media (min-width: 1024px) {
  .settings {
    padding: 1rem 1.5rem;
  }
}
