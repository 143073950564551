.dashed-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border: 2px dashed var(--file-dropzone-border-color);
  background-color: var(--file-dropzone);
  border-radius: var(--border-radius);
  text-align: center;
  min-height: 150px;

  .dashed-area-buttons {
    margin-top: auto;

    button {
      width: 100%;
      color: #fff;
      border-color: #fff;
      border-width: 1px;
    }
  }

  svg {
    margin: 0 auto;
  }

  &.-success {
    background-color: var(--file-dropzone-success);
    .file-name {
      color: #fff;
    }
  }

  &.-error {
    background-color: var(--file-dropzone-error);

    .file-name {
      color: #fff;
    }
  }
}
