.file-selector {
  h1 {
    margin: 0 0 20px;
    text-align: center;
  }

  h2 {
    margin: 0.5rem 1.5rem;
  }

  hr {
    margin: 0.25rem 1rem;
    border-color: #545458a6;
  }

  ul {
    list-style: none;
    margin: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--modal-scrollbar-foreground);
    }

    &::-webkit-scrollbar-track {
      border-radius: 50px;
      background: var(--modal-scrollbar-background);
    }

    &::-webkit-scrollbar-button {
      display: none;
      width: 0;
    }

    @supports (not (selector(::-webkit-scrollbar))) {
      scrollbar-color: var(--modal-scrollbar-foreground)
        var(--modal-scrollbar-background);
      scrollbar-width: thin;
    }

    &.file-list {
      margin-bottom: 1rem;
      margin-right: 15px;
      padding-left: 11px;
      margin-top: 5px;
      height: 28rem;
    }
  }

  .file-list-all {
    margin: 10px 0 0 0;
    overflow: hidden;
  }

  .select-all {
    overflow: hidden;

    label {
      color: var(--main-text-color);
      font-weight: 600;
    }
  }

  .delete-button {
    right: 58px !important;

    svg {
      margin-left: 1px;
    }
  }

  .delete-button:hover {
    color: var(--main-text-color) !important;
    background-color: var(--modal-button-background-hover) !important;
  }
}

.file-list .select-all .checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-checkbox-border);
}

.file-list {
  max-height: 45vh;
  overflow: auto;

  .label-text > div:not(.date) {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.file-list-item {
  border-bottom: 1px solid transparent;
  cursor: pointer;
  min-height: 53px;
  display: flex;
  max-height: 3.25rem;
  padding: 0.5rem;
  align-items: center;
  gap: 0.75rem;
  flex: 1 0 0;
  transition: background-color 0.4s ease-out;
  background-color: transparent;
  border-radius: 0.75rem;

  &:hover {
    background: var(--file-list-hover-bg);
  }

  &.type-index {
    max-height: 4rem;
  }
}

.file-available.checkbox-selection label,
.file-unavailable.checkbox-selection label {
  cursor: pointer;
  transition: color 0.2s ease-out;
}

.file-available.checkbox-selection label {
  color: var(--file-text-available);
}

.file-unavailable.checkbox-selection label {
  color: var(--file-text-unavailable);
}

.file-available.checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-checkbox-border);
}

.file-unavailable.checkbox-selection .ant-checkbox-inner {
  border-color: var(--file-text-unavailable);
}

.ant-modal.select-file-modal {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    padding-left: 0;
  }

  .ant-modal-footer {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: auto;
    width: 100%;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: space-between;
  }

  .warning {
    color: var(--main-danger-color);
    text-align: center;
    font-size: 0.8rem;
    padding: 5px 0;
  }

  .info {
    color: var(--main-text-color-light);
    text-align: center;
    font-size: 0.8rem;
    padding: 5px 0;
    font-style: oblique;
  }
}

.file-selector.has-files-applied {
  .files-applied {
    margin-top: 8px;

    h2 {
      font-weight: 600;
      padding: 6px 0;
      font-size: 0.9375rem;
    }

    .file-available {
      width: 100%;
      justify-content: start;
    }

    label {
      max-width: unset;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-list {
      max-height: 460px;
      overflow-x: hidden;
    }
  }

  .file-list {
    margin-bottom: 0;
  }

  .files-applied .file-list-item:hover {
    background: none;
    cursor: default;

    label {
      cursor: default;
    }
  }
}

.file-selector .segment-bar {
  margin: 0 15px;
}

.file-selector .file-list-active-wrapper {
  flex: auto;
}

.file-selector .file-list-active-wrapper > div {
  flex: auto;
}
