.tag {
  position: relative;
  display: inline-flex;
  align-items: center;

  &.type-default {
    background: #636366;
    border-radius: 0.5rem;
    color: #fff;
  }

  &.type-bubble {
    border-radius: 30px;
  }

  &.bg-teal {
    background-color: var(--stryker-teal);
  }

  &.bg-gray {
    color: var(--badge-text-color);
    background-color: var(--badge-background-color);
  }

  &.bg-green {
    background-color: var(--stryker-performance-green);
  }

  &.bg-red {
    background-color: var(--stryker-performance-red);
  }

  &.text-light {
    color: #fff;
  }

  &.text-dark {
    color: #000;
  }

  &.size-small {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding: 3px 10px;
  }

  &.size-medium {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 138.462% */
    padding: 6px 14px;
  }

  &.size-large {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.225rem; /* 138.462% */
    padding: 8px 16px;
  }
}
