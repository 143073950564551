.select {
  .ant-select {
    width: 100%;
    height: var(--input-height);

    .ant-select-selector {
      color: var(--input-text-color);
      background-color: var(--input-background-color);
      border: none;
    }

    .ant-select-selection-item {
      color: var(--input-text-color);
    }

    .ant-select-arrow {
      color: var(--main-text-color);
    }
  }
}
