.segment-bar {
  display: flex;
  padding: 0.25rem;
  align-items: flex-start;
  gap: 0.25rem;
  flex-shrink: 0;
  align-self: stretch;
  background-color: var(--segmented-control-bg);
  border-radius: 0.75rem;
  max-width: 800px;
  height: 32px;
  overflow: hidden;
}

.segment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--tab-bar-text-color);
  border-radius: 0.5rem;
  border: 1px rgba(255, 255, 255, 0.15);
  cursor: pointer;
  font-weight: 600;
  position: relative;

  &:hover {
    color: var(--segmented-control-text-color-active);
  }

  .segment-label {
    position: absolute;
    top: 0;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &.active {
    color: var(--segmented-control-text-color-active);
    border: 1px solid var(--segmented-control-active-border-color);

    &:after {
      position: absolute;
      top: 0;
      content: '';
      background-color: var(--segmented-control-bg-active);
      width: 100%;
      height: 100%;
      z-index: 50;
      left: 0;
      border-radius: 0.5rem;
    }
  }

  &.active.slideLeft {
    &:after {
      animation: slideInFromLeft 0.2s ease-out;
    }
  }

  &.active.slideRight {
    &:after {
      animation: slideInFromRight 0.2s ease-out;
    }
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
