.confirmation-dialog {
  overflow: hidden;
  top: 300px !important;
  border-radius: 25px;
  .confirmation-dialog-text {
    text-align: center;
    font-size: 15px;
  }

  .ant-modal-footer {
    margin-top: 2rem;
  }
}
