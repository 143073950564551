.file-chit-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 256px));
  gap: 4px;

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(150px, 190px));
    gap: 4px;
  }
}
