.markdown-container {
  max-width: 568px;
}

.markdown {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    display: block;
    width: 100%;
    size: 17px;
    line-height: 25px;
    font-weight: 400;
    margin: 10px 0;
  }

  p:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-weight: normal;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 34px;
    line-height: 41px;
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
  }

  h3 {
    font-size: 22px;
    line-height: 28px;
  }

  h4 {
    font-size: 20px;
    line-height: 25px;
  }

  h5 {
    font-size: 17px;
    line-height: 22px;
  }

  h6 {
    font-size: 15px;
    line-height: 20px;
  }

  blockquote {
    border-left: 2px solid var(--markdown-editor-border);
    font-size: 17px;
    font-weight: bold;
    padding: 0.9rem 15px;
    margin: 20px 0;
  }

  ol,
  ul {
    margin: 0 0 0 1rem;
    padding: 20px 0;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    padding: 1px 0;
  }

  table {
    padding: 10px 0;
    margin: 10px 0;
    border-collapse: separate;
    border-spacing: 0;
    min-width: 100%;
  }

  table tr th,
  table tr td {
    border-right: 1px solid var(--markdown-editor-border);
    border-bottom: 1px solid var(--markdown-editor-border);
    padding: 8px;
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 1px solid var(--markdown-editor-border);
  }

  table tr th {
    text-align: left;
    border-top: solid 1px var(--markdown-editor-border);
  }

  table tr:first-child th:first-child {
    border-top-left-radius: var(--markdown-table-border-radius);
  }

  table tr:first-child th:last-child {
    border-top-right-radius: var(--markdown-table-border-radius);
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: var(--markdown-table-border-radius);
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: var(--markdown-table-border-radius);
  }

  pre {
    padding: 20px;
    background-color: var(--markdown-code-bg);
    overflow-x: auto;
    overflow-y: clip;
    font-size: 0.9rem;
    scroll-behavior: smooth;
    border-radius: 2px;
  }
}

.code-editor-option-bar {
  display: flex;
  align-items: center;
  background: var(--markdown-code-copy-bg);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 5px 12px;
}

.code-editor-header {
  font-size: 1.1rem;
}

.copy-code {
  display: flex;
  background: none;
  border: none;
  color: var(--main-text-color);
  margin-left: auto;
  font-size: 0.9rem;
  align-items: center;
  justify-content: center;
}

.copy-code:hover {
  background: none !important;
  border: none !important;
}
