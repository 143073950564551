.ant-btn {
  height: var(--button-height);
  max-width: 360px;
  color: var(--main-text-color);
  font-size: var(--buton-text-size);
  border-radius: var(--button-border-radius) !important;
  font-family: var(--font-body) !important;
  -webkit-appearance: initial;
  appearance: initial;
}

.ant-btn-default {
  height: var(--button-height);
  max-width: 360px;
  border: 1px solid transparent !important;
  color: var(--button-text-default) !important;
  background: var(--button-active-color) !important;
  border-radius: var(--button-border-radius) !important;

  &:hover {
    background: var(--button-active-color-hover) !important;
    border-color: var(--button-active-color-hover) !important;
  }

  &:active {
    background: var(--button-active-color-active) !important;
    border-color: var(--button-active-color-active) !important;
  }

  &.secondary {
    color: #fff !important;
    background: var(--stryker-teal) !important;

    &:hover {
      background: var(--stryker-teal-hover) !important;
      border-color: var(--stryker-teal) !important;
    }
  }
}

.ant-btn-text {
  color: var(--main-text-color);
  height: auto;
  &:hover {
    color: var(--main-text-color) !important;
    background: #5c5c5d;
  }
  &:active {
    color: var(--main-text-color) !important;
    background: #747477;
  }
}

.ant-btn-dashed {
  color: var(--main-text-color);
  border: 2px solid var(--main-text-color);
  border-color: #8a8a8b;
  background: transparent;

  &:hover {
    color: var(--main-text-color) !important;
    border-color: var(--main-text-color) !important;
    background: var(--button-dashed-hover) !important;
  }
  &:active {
    color: var(--main-text-color) !important;
    border-color: var(--main-text-color) !important;
    background: #747477 !important;
  }
}

.ant-btn-circle {
  display: flex;
  justify-content: center;
  border-radius: 32px !important;
  height: auto;
}

.ant-btn.button-inline-link {
  display: inline;
  color: var(--hyperlink-color) !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0;
  height: auto !important;

  span {
    text-decoration: underline !important;
  }
}

.ant-btn.button-inline-link.no-color {
  color: var(--main-text-color) !important;
}

.ant-btn-default.button-inline-link:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  border: none !important;
  background: none !important;
  color: var(--hyperlink-color) !important;

  span {
    text-decoration: underline;
  }
}

.ant-btn.full-size {
  width: 100% !important;
  max-width: unset;
}

.ant-btn.half-size {
  width: 50% !important;
}

.ant-btn.ant-btn-default {
  &.unavailable {
    background-color: var(--button-grayed-color) !important;
    border-color: var(--button-grayed-color) !important;
    color: var(--button-text-gray) !important;

    &:hover {
      background-color: var(--button-grayed-color) !important;
      border-color: var(--button-grayed-color) !important;
    }
  }

  &.active {
    background-color: var(--button-active-color) !important;
    border-color: var(--button-active-color) !important;
    color: var(--button-text-default) !important;

    &:hover {
      background-color: var(--button-active-color-hover) !important;
      border-color: var(--button-active-color-hover) !important;
      color: var(--button-text-default) !important;
    }
  }

  &.danger {
    background-color: var(--button-danger-color) !important;
    border-color: var(--button-danger-color) !important;
    color: var(--button-text-default) !important;

    &:hover {
      background-color: var(--button-danger-color-hover) !important;
      border-color: var(--button-danger-color-hover) !important;
    }
  }
}
