.conversation-area {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--main-bg-color);
  flex: 1;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 0px;
    opacity: 0;
    left: 0;
    bottom: 0;
    transform: translateY(150px);
    transition: opacity 0.2s ease-out;
  }
}

.-scrolling .conversation-area {
  &:after {
    left: 0;
    bottom: 0;
    opacity: 1;
    box-shadow: 0px -23px 45px 17px var(--scrolling-question-input-shadow-color);
    transform: translateY(-121px);
  }
}

.conversation-input-wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 145px;
  width: 100%;
  margin: 0 auto;
  z-index: var(--z-med-priority);
  flex-grow: 0;
  left: 0;
  background: linear-gradient(180deg, transparent 0%, var(--main-bg-color) 35%);
}

.conversation-input-controls {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 15px;
  width: 100%;
}

.center-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .goldie-logo {
    opacity: 0.3;
  }
}

.conversation-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.ant-spin-dot-item {
  background-color: var(--main-text-color) !important;
}

@media (min-width: 1024px) {
  .conversation-input-controls {
    max-width: var(--center-column-width);
    padding: 0 0 2rem;
  }
}
