.context-button {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 35px;
  align-items: center;
  cursor: pointer;
  padding: 18px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition:
      background-color 0.3s ease,
      transform 0.3s ease;
    transform: translateY(-2px);
  }

  &:hover::after {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(0px);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &::after {
      display: none;
    }
  }

  &.disabled.selected {
    svg {
      color: var(--main-text-color);
    }
  }

  svg {
    width: 32px;
    height: 32px;
    margin-left: 2px;
  }

  &.selected {
    svg {
      color: var(--context-button-selected);
    }
  }

  .context-button-title {
    display: flex;
    align-items: center;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    margin-bottom: 6px;
  }

  .context-button-title-tag {
    margin-left: 0.5rem;
  }

  p {
    font-size: 0.75rem;
    line-height: 20px;
    max-width: 95%;
  }
}
