.checkbox-selection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  label {
    color: var(--main-text-color-light);
    cursor: pointer;

    .sublabel {
      font-size: 0.65rem;
    }

    .header {
      font-weight: 600;
      color: var(--main-text-color);
    }
  }

  .label-text {
    max-width: 90%;
    flex-grow: 1;
  }

  .ant-checkbox .ant-checkbox-inner {
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    border-width: 2px;
    background: transparent;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: var(--radio-circle-color) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after,
  .ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-color: var(--radio-circle-color) !important;
    transform: rotate(45deg) scale(1.4) translate(-35%, -50%);
  }

  &.disabled {
    .ant-checkbox-wrapper-disabled,
    label {
      color: var(--main-text-color-disabled);
      span {
        border-color: var(--main-text-color-disabled);
      }
    }
  }
}
