.response-feedback {
  display: flex;
  align-items: flex-start;
  align-content: center;
  height: 35px;
  max-width: 586px;
  margin: 0 auto;
  padding-left: 2.65rem;

  button {
    align-items: center;
    width: 32px;
    height: 32px;

    &:hover {
      color: var(--main-accent-color-hover) !important;
      svg {
        stroke: var(--main-accent-color-hover);
      }
    }
    &:active {
      color: var(--main-accent-color-active) !important;
      svg {
        stroke: var(--main-accent-color-active);
      }
    }
    &.-active {
      color: var(--button-selected-text-color) !important;
      max-height: 32px;

      svg {
        stroke: var(--button-selected-text-color);
      }
      background-color: var(--button-selected-bg-color);
    }
    &.-active:hover {
      color: var(--main-accent-color-hover) !important;
      svg {
        stroke: var(--main-accent-color-hover);
      }
      background-color: var(--button-selected-bg-color);
    }
  }

  .clipboard-action {
    margin-right: 20px;
  }
}

.ant-notification {
  transform: unset !important;
}

.ant-notification-notice {
  display: flex;
  align-items: center;
  width: 100% !important;
  height: auto;
  max-height: 40vh;
  padding: 1rem 0;
}

.ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-content {
  display: flex;
  max-width: 90% !important;
}

.ant-notification-notice-description {
  color: var(--main-text-color) !important;
  font-size: 1.1em !important;
}

.ant-notification-notice.warning .ant-notification-notice-description {
  color: var(--main-danger-color) !important;
}

.ant-notification-notice-wrapper {
  background: var(--toast-bg) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 80vw !important;
  max-width: 650px !important;
}

.ant-notification-notice-close {
  color: var(--toast-close-button-text-color) !important;
  background: var(--toast-close-button-bg-color);
  border-radius: 2em !important;
  padding: 0.3em 0.5rem !important;
  height: 3rem !important;
  width: 3rem !important;
  font-size: 1.2rem !important;
  text-align: center !important;
  display: flex !important;
  cursor: pointer;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  margin-left: auto;
  position: unset !important;
}

.feedback-modal-textarea {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--input-background-color);

  textarea {
    border-bottom: 2px solid var(--input-background-color);
    background-color: rgba(120, 120, 128, 0.36);
    color: var(--main-input-text-color);
    border: none;
    height: 215px;
    padding: 1rem;
    font-size: 1.1rem;
    resize: none;
    border-radius: 30px;
  }

  textarea:focus,
  textarea:hover {
    background-color: var(--main-input-bg-color);
  }

  textarea::placeholder {
    color: var(--textarea-placeholder);
    font-size: 15px;
  }
}

.feedback-modal-types {
  padding: 0.5rem 0.5rem 1rem;
}

.feedback-modal-types .checkbox-selection {
  margin-top: 1rem;
}

.submit-feedback-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  max-width: unset;
  width: 100%;
}

@media (min-width: 640px) {
  .response-feedback {
    padding-left: 1.65rem;
  }

  .drawer-open .ant-notification {
    inset: 24px auto auto 60% !important;
  }
}

.feedback-modal {
  .ant-modal-content {
    padding: 25px 15px;
  }

  .ant-modal-footer {
    align-items: center;
    justify-content: center;
  }
}
