.overtake-back-button {
  position: absolute;
  left: 12px;
  top: 12px;
  background: var(--modal-button-background) !important;
}

.overtake-back-button:hover {
  background: var(--modal-button-background-hover) !important;
  border: none !important;
}

.resource-detail-modal {
  color: var(--main-text-color-light);

  .primary-details {
    margin-bottom: 10px;

    h1 {
      margin: 0;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      color: var(--main-text-color);
    }

    h3 {
      text-transform: capitalize;
      font-size: 16px;
    }

    p {
      font-size: 16px;
    }
  }

  .detail-section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--modal-divider);
    padding: 10px 0;

    &:last-child {
      border-bottom: 1px solid var(--modal-divider);
    }

    strong {
      color: var(--main-text-color);
      margin-bottom: 5px;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    p {
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .datetime-row {
    display: flex;
  }

  .datetime-row-date {
    margin-left: auto;
    color: var(--main-text-color);
  }
}
