.conversation-history-item {
  .conversation-history-item-button {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
    align-items: center;
    border-radius: 0.5rem;
    height: 2.5rem;
    cursor: pointer;

    margin-bottom: 0.7rem;
    color: var(--main-text-color);
    padding: 0 0.5rem;
    width: 100%;

    .menu {
      width: 40px;
    }

    &:hover {
      color: var(--main-text-color) !important;
      background-color: var(--conversation-history-hover-color) !important;

      svg {
        color: var(--main-text-color);
        font-size: 1.5rem;
      }
    }
  }
  &.editmode .conversation-history-item-button,
  &.editmode:hover .conversation-history-item-button {
    grid-template-columns: 1fr 40px;
    color: var(--main-text-color) !important;
    background-color: var(--conversation-history-selected-color) !important;
  }

  .conversation-name {
    display: grid;
    grid-template-columns: 32px 1fr;
    text-align: left;

    white-space: nowrap;
    padding: 0 0.25rem;
    .conversation-name-text {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }

    input {
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      color: var(--main-text-color);
      font-size: 1rem;
      font-weight: 500;
      &:focus {
        outline: none;
      }
    }
  }

  .menu {
    position: absolute;
    z-index: 10;
    right: 0;
    height: 30px;
    padding: 0.5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
    button {
      width: 16px !important;
      height: 30px;
      margin: 0rem;
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }
    .save-button {
      margin-top: 4px;
    }
  }
  &.editmode .menu {
    position: relative;
  }
}
