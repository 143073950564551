.error-page {
  margin: 0 auto;
  padding-top: 20vh;

  h1 {
    text-align: center;
    color: var(--main-text-color);

    a {
      color: var(--main-text-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
