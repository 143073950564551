.card {
  background-color: var(--card-bg-color);
  border-radius: 36px;
  padding: 24px;
  color: var(--main-text-color);
}

.card-wrapper {
  width: 512px;
  min-height: 512px;
  margin: 0 auto;
}

.card-wrapper .card {
  min-height: 512px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
