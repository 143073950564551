.drawer {
  width: 100%;
  height: 100%;
  transition:
    width 0.2s,
    background-color 0.4s 0.1s,
    height 0.2s 0.1s;
  position: relative;
}

.drawer.-collapsed {
  width: 50px;
  height: 100%;
  background-color: transparent;
}
