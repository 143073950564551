.file-upload {
  h1 {
    margin: 0 0 20px;
    text-align: center;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 20px;
  }

  .file-upload-area {
    cursor: pointer;
  }

  #fileUpload {
    display: none;
  }

  .file-type-selector {
    margin: 2rem 0;
    padding-left: 8px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0.85rem 0;
      }
    }
  }

  .uploading-text {
    text-align: left;
    max-width: 80%;
    margin: 0 auto;
  }

  .public-internal-fields {
    label,
    p {
      display: block;
      padding: 0 8px 8px;
    }
  }

  .product-association {
    margin: 1.5rem 0;
  }

  .info-text {
    p {
      font-size: 12px;
      line-height: 16px;
      margin: 1rem 0;
      color: var(--info-text);
    }
  }
  .ant-select-selection-placeholder {
    color: var(--input-placeholder-color);
  }

  .warning-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    color: var(--main-danger-color);

    svg {
      height: 1.5rem;
      width: 1.5rem;
      min-width: 1.5rem;
      fill: var(--main-danger-color);
    }
  }
}

.file-upload-dropzone {
  .upload-message {
    font-size: 1rem;
  }

  .upload-allowed-types {
    font-size: 0.8rem;
  }
}

.file-name {
  margin-top: auto;
  display: inline-block;
}
