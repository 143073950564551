.ant-modal.select-ai-model {
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.model-selection {
  width: 100%;
  max-width: var(--center-column-width);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-background-color) !important;
    border: none !important;
    color: var(--main-text-color) !important;
    width: 100% !important;
    min-height: 3rem;
    text-align: center;
    max-width: 100%;
    margin: 0 10px;
    height: auto !important;

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      background-color: var(--input-background-color-hover) !important;
      border: none !important;
    }

    span {
      display: block;
      width: 100%;
      text-align: center;
    }

    svg {
      margin-left: auto;
    }
  }
}

.gpt-model-selection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gpt-model-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .gpt-model-item {
    width: 100%;
  }

  .warning {
    display: block;
  }
}

.model-selection-text {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .model-selection-text-container {
    width: 100%;
  }
  .model-selection-text-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 0.75rem;
    font-weight: 700;

    &.all-prompts-exhausted {
      opacity: 0.5;
    }
  }
  .user-limit-notice {
    font-size: 0.7em;
    line-height: 0.7em;
    padding: 0 0 0.5rem 0;
    margin: 0.3rem 0 0 0;
    font-weight: 300;
  }

  .badge {
    color: var(--badge-text-color);
    background-color: var(--badge-background-color);
    border-radius: 0.4rem;
    width: auto;
    padding: 0.3em 0.6em;
    font-size: 0.7em;
    display: inline;
    font-weight: 300;
  }
}

@media (min-width: 1024px) {
  .model-selection button {
    margin: 0;
  }
}
