.message {
  .response {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 90%;

    img {
      width: 28px;
      height: 28px;
    }

    &.error {
      > span {
        color: var(--main-text-warning);
      }
    }
  }
}

.generated-response-row {
  .tag {
    margin-bottom: 10px;
    top: -1px;
  }
}

@media (min-width: 640px) {
  .message .response {
    width: 100%;
  }
}
