.home {
  position: relative;
  height: 100vh;
  color: var(--main-text-color);
  overflow: clip;

  aside {
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--sidebar-bg-color);
    padding: 5px 2px 5px 5px;
    justify-content: flex-start;
    z-index: var(--z-high-priority);
    max-height: 100vh;
    overflow: hidden;

    .conversation-histories {
      display: flex;
      flex-direction: column;
      height: 88%;
      box-sizing: border-box;
      overflow-y: scroll;
      scroll-behavior: smooth;
      scrollbar-color: var(--main-bg-color) var(--sidebar-bg-color);
    }

    .loading {
      padding: 1rem 1rem;
    }
  }

  aside .logo-container {
    display: flex;
    align-items: center;

    .logo {
      justify-content: flex-start;
    }

    .collapse-control {
      margin-left: auto;
      margin-right: 8px;
    }
  }

  &.-collapsed {
    grid-template-columns: 1fr;
    .drawer {
      position: absolute;
      z-index: 10;
    }
  }

  .new-conversation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius);
      color: var(--main-text-color);
      padding: 0.8rem;
      height: auto;
      width: 100%;
      margin: 0.8rem 0.5rem;
    }
  }

  article {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--main-bg-color);
    height: 100dvh;
  }
}

.collapse-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--input-background-color);
  color: var(--collapse-control-color);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem;

  &:hover {
    background-color: var(--input-background-color-hover);
  }
}

.paginated-loading-conversations {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@media (min-width: 1024px) {
  .home {
    display: grid;
    grid-template-columns: 275px 1fr;
  }

  .collapse-control.-mobile {
    display: none;
  }
}
