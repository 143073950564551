.checkbox-multiselect {
  margin: 10px 0 0;
  padding: 5px 0.5rem 5px 19px;

  .header {
    font-size: 0.9375rem;
    font-weight: 600;
    color: var(--main-text-color);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    height: 3px;
    background-color: var(--file-selection-checkbox-border);
    border-radius: 0.75rem;
    width: 10px;
  }
}
