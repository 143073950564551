.search-box {
  .ant-input-search {
    input {
      padding: 0.5rem 1rem;
      border-radius: var(--input-border-radius);
      background-color: var(--input-background-color);
      color: var(--input-text-color);
      border: none;

      &::placeholder {
        color: var(--input-placeholder-color);
        opacity: 1; /* Firefox */
      }
    }
  }
  .ant-input-group-addon {
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    border: none;
    color: var(--input-text-color);
    padding: 0 0.25rem !important;

    .ant-btn {
      border-radius: var(--input-border-radius) !important;
    }
    svg {
      fill: var(--input-text-color);
    }
  }
}
