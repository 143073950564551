.conversation-response-sources {
  margin-bottom: 1em;
  .sources-button {
    font-size: 0.7em;
    background: rgba(120, 120, 128, 0.36);
  }
}

.conversation-response-source-modal {
  height: 40rem !important;
}
.conversation-response-source-list {
  overflow: auto;
  height: 33rem;
  .source-item {
    align-items: center;
    display: grid;
    grid-template-columns: 2em 1fr;
    padding: 0.2em 0 0.5em 0;
    svg {
      height: 1.1em;
      width: 1.1em;
    }
    a {
      text-decoration: underline;
    }
  }
}
