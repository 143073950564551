.splash {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  z-index: var(--z-med-priority);
  overflow: hidden;

  .stryker-logo {
    padding: 0;
    margin-bottom: 48px;
    justify-content: left;

    svg {
      color: var(--main-text-color);
    }
  }
}

.goldie-emphasis {
  color: var(--stryker-gold-dark);
}

.splash-content {
  display: flex;
  width: 100%;
  max-width: 512px;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  z-index: var(--z-high-priority);

  h1 {
    font-size: 1.85rem;
    font-weight: 700;
    margin-bottom: 25px;
    color: var(--main-text-color);
  }

  p {
    margin-bottom: 10px;
    color: var(--main-text-color);
  }

  button {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    padding: 0.8rem;
    height: auto;
  }
}

/* sm */
@media (min-width: 640px) {
  .splash-content {
    padding: 0;
  }
}
