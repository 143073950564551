.product-association {
  .ant-input {
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    height: var(--input-height);
    border: none;
    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }
}
