.radio-selection {
  display: grid;
  grid-template-columns: 1fr 1.5rem;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  label {
    width: 100%;
    cursor: pointer;
  }

  .radio-selection-description {
    font-size: 12px;
    line-height: 16px;
    color: var(--info-text);
  }

  .radio-selection-input {
    margin-top: 1rem;
  }

  input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--radio-background-color);
    /* Not removed via appearance */
    margin: 0;

    cursor: pointer;
    font: inherit;
    color: currentColor;
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
      content: '';
      width: 0.8em;
      height: 0.8em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--radio-circle-color);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
    &:checked::before {
      transform: scale(1);
    }
  }

  &.single-line {
    display: flex;
    align-items: center;
    grid-template-rows: unset;

    > div:first-child {
      width: 100%;

      label {
        display: inline-block;
        width: 100%;
      }
    }

    .radio-selection-input {
      margin-top: 0;
    }
  }
}
