.noaccess-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  z-index: var(--z-med-priority);
  overflow: hidden;

  article {
    display: flex;
    width: 100%;
    max-width: 514px;
    flex-direction: column;
    justify-content: center;
    z-index: var(--z-high-priority);
    align-items: flex-start;
  }

  p {
    margin-bottom: 10px;
    color: var(--main-text-color);
    padding: 0 1rem;
  }

  a {
    color: var(--hyperlink-color);
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    margin-top: 10px;
    padding: 0.65rem 0 2.2rem 0;
  }
}
