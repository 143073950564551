.checkbox-file-selection {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  grid-gap: 1em;
  align-items: center;

  label {
    color: var(--main-text-color-light);
    cursor: pointer;
    max-width: 310px;

    .sublabel {
      font-size: 0.6875rem;
    }

    .header {
      font-weight: 600;
      color: var(--main-text-color);
    }
  }

  .primary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 0.9375rem;
  }

  .date {
    font-size: 0.65rem;
  }

  .label-text {
    flex-grow: 1;
  }

  .ant-checkbox .ant-checkbox-inner {
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    border-width: 2px;
    background: transparent;
    border-color: var(--file-selection-checkbox-border);
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: var(--radio-circle-color) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after,
  .ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-color: var(--radio-circle-color) !important;
    transform: rotate(45deg) scale(1.4) translate(-35%, -50%);
  }

  .metadata {
    display: grid;
    color: var(--file-metadata);
    grid-template-columns: 2fr 1fr;

    div {
      flex: 1;
    }

    .date {
      text-align: right;
    }
  }

  &.disabled {
    .ant-checkbox-wrapper-disabled,
    label {
      color: var(--main-text-color-disabled);
      span {
        border-color: var(--main-text-color-disabled);
      }
    }
  }

  &.is-checked {
    .primary {
      color: var(--file-text-name);
    }
  }

  &.is-errored,
  &.is-deleted,
  &.is-deleting {
    .primary {
      opacity: 0.5;
    }

    .sublabel {
      color: var(--main-danger-color);
    }
  }

  &.is-checked.is-errored {
    .primary {
      opacity: 1;
    }
  }
}

.open-resource-metadata {
  color: var(--main-text-color);
  margin: 0 !important;
  padding: 0 !important;
  height: 25px;
}

.type-index .metadata {
  display: flex;
}

.wrap-sublabel {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;
}
